import React from "react"
import PostItem from "./PostItem"
import {
  posts__wrapper,
  posts__title,
  posts__wrapper__items,
} from "../styles/posts.module.scss"

const Posts = ({ posts = [] }) => {
  return (
    <div className={posts__wrapper}>
      <div className={posts__wrapper__items}>
        {posts.map((el, k) => (
          <PostItem {...el} key={k} />
        ))}
      </div>
    </div>
  )
}

export default Posts
