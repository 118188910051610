import React from "react"
import {
  Layout,
  FlexCol,
  Parallax,
  NewsletterSection,
} from "@components/layout"
import { Posts, CategoryItem, BlogItem } from "@components/blog"
import { Breadcrumbs } from "@components/shared"
import { withHtml } from "@components/logic"

import {
  info__body__description,
  info__body__data,
  info__body__subtitle,
} from "../styles/info.module.scss"

const Info = () => {
  return (
    <section className={info__body__description}>
      <div className={info__body__data}>
        <div>
          1000+ <p>Liczba obsłużonych klientów</p>
        </div>
        <div>
          20 <p>Lat obecności na rynku</p>
        </div>
        <div>
          100 <p>Liczba spółek założonych dla naszych klientów</p>
        </div>
      </div>
    </section>
  )
}

export default Info
