import React from "react"
import {
  Layout,
  FlexCol,
  Parallax,
  NewsletterSection,
} from "@components/layout"
import { Posts, CategoryItem, BlogItem, Info } from "@components/blog"
import { Breadcrumbs } from "@components/shared"
import { withHtml } from "@components/logic"

import {
  blog__title,
  blog__main,
  blog__categoryItem__wrap,
} from "./styles/blog.module.scss"

const Categories = ({ categories = [] }) => {
  const shuffledCategories = categories.sort(() => Math.random() - 0.5)
  return (
    <React.Fragment>
      <div className={blog__categoryItem__wrap}>
        {shuffledCategories
          .filter((el) => el.posts.nodes.length > 0)
          .map((el, k) => (
            <CategoryItem {...el} count={el.posts.nodes.length} key={k} />
          ))}
      </div>
    </React.Fragment>
  )
}

const Blog = ({ pageContext }) => {
  const { posts, page, categories } = pageContext
  return (
    <Layout {...page}>
      <div className={blog__main}>
        <Breadcrumbs
          elements={[
            {
              label: page.title,
              url: `/blog/`,
            },
          ]}
        />

        <h1 className={blog__title}>Co u nas można przeczytać</h1>

        {/* <Categories categories={categories} /> */}
        <Posts
          // title={"Każdy artykuł ma coś w sobie"}
          posts={posts}
          recommended={true}
        />
        <Info />
        <NewsletterSection />
      </div>
    </Layout>
  )
}

export default Blog
