import React from "react"
import {
  ResizedImage as Image,
  AnimatedSection,
  AnimatedLink,
} from "@components/shared"
import { withHtml } from "@components/logic"
import icon from "../img/officeE.png"

import {
  postItem,
  post,
  post__wrapper,
  post__content,
  post__wrapper__text,
  post__img,
  post__tags,
  post__btn,
  post__tags__wrap,
} from "../styles/postitem.module.scss"

const Title = withHtml("h3")
const Description = withHtml("article")

const formatDate = (date) => {
  const formated = new Date(date)
  return `${formated.getUTCDate()}.${formated.getUTCMonth()}.${formated.getUTCFullYear()}`
}

const Tags = ({ tags }) => {
  const { nodes } = tags
  return (
    <div className={post__tags__wrap}>
      {nodes.map(({ slug, name }, i) => {
        return (
          <AnimatedLink to={`/tags/${slug}/`} key={i}>
            {"#" + name}
          </AnimatedLink>
        )
      })}
    </div>
  )
}

const PostItem = ({ title, slug, date, featuredImage, tags, categories }) => {
  return (
    <div className={postItem} as={"article"}>
      <a className={post}>
        <div className={post__img}>
          {/* <Image {...featuredImage} /> */}
          <img src={icon} alt="" />
        </div>
        <div className={post__wrapper__text}>
          <div className={post__content}>
            {/* <Tags tags={tags} className={post__tags} /> */}
            <Title>{title}</Title>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut Lorem ipsum dolor sit amet,
              consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut
            </p>
          </div>
          <AnimatedLink className={post__btn} to={`/blog/${slug}/`}>
            <button>Czytaj więcej</button>
          </AnimatedLink>
        </div>
      </a>
    </div>
  )
}
export default PostItem
